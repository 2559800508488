#root {
  height: 96%;
  padding: 1em;
}

.App-logo {
  height: 60px;
  pointer-events: none;
}

@media (max-width: 1024px) {
  .header-row > h1 {
        font-size: 12px;
    }
}

.control-form {
    padding-left: 1em;
    padding-right: 1em;
}

.control-form-fields {
    margin-top:1em;
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    flex-direction: column;
}


.MuiTableCell-root {
    font-size: small !important;
    padding: 6px !important;
}

/*.table-container {*/
/*    display: flex;*/
/*    align-content: center;*/
/*    align-items: flex-start;*/
/*    justify-content: flex-start;*/
/*    height: 100%;*/
/*    width: 100%*/
/*}*/

/*.table-container > table {*/
/*    width: 80%;*/
/*    height: 100%;*/
/*    max-height: 700px;*/
/*}*/

/*th*/
/*{*/
/*  font-weight: normal;*/
/*  vertical-align: bottom;*/
/*  text-align: center;*/
/*    font-size: x-small;*/
/*}*/

th span {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
}

.label-cell {
    white-space: nowrap;
    text-align: right !important;
}

.header-label-cell {
    vertical-align: bottom !important;
    border: 0 solid black !important;
    text-align:center !important;
}

td {
  border: 1px solid silver;
  /*min-width: 0.5em;*/
  /*  min-height: 0.5em;*/
    font-size: x-small;
}

.black-cell {
    /*background-color: black;*/
    background-color: #1e1e1e;
}

.consistent {
    /*background-color: green;*/
    background-color: forestgreen;
}

.inconsistent {
    /*background-color: red;*/
    background-color: orangered;
}