body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    color: hsla(0, 0%, 0%, 0.8);
    background-color: #fafafa;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h5 {
    margin-block-start: 0;
    margin-block-end: 0;
}
